import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import LoginPage from "../../pages/Login";
import PublicLayout from "../../layout/public";
import SearchScreen from "../../pages/Search";
import StoreProfileScreen from "../../pages/Profile/Store";
import CompanyProfileScreen from "../../pages/Profile/Company";
import Menu from "../../pages/Menu";
import HomeScreen from "./Home";
import {createBrowserHistory} from "history";

const history = createBrowserHistory()

export const PublicRouter = props => {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path={"/sign*"} component={LoginPage}/>
                <Route exact path="*" component={PublicRoutes}/>
            </Switch>
        </Router>
    )
}

const PublicRoutes = props => {
    return (
        <PublicLayout>
            <Switch>
                <Route exact path="/search" component={SearchScreen}/>
                <Route exact path="/search/:search" component={SearchScreen}/>
                <Route exact path="/stores/:store_id" component={StoreProfileScreen}/>
                <Route exact path="/companies/:company_id" component={CompanyProfileScreen}/>
                <Route exact path="/companies/:company_id/menu" component={Menu}/>
                <Route exact path="*" component={HomeScreen}/>
            </Switch>
        </PublicLayout>
    )
}

export default PublicRouter;
