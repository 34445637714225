import React, {useRef} from "react";
import {Carousel} from "antd";

const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

const HomeScreen = props => {
    const carousel = useRef();

    const onBack = () => {
        if (carousel.current) {
            carousel.current.prev()
        }
    }

    const onNext = () => {
        if (carousel.current) {
            carousel.current.next()
        }
    }

    return (
        <div>
            <Carousel autoplay ref={carousel}>
                <div>
                    <h3 style={contentStyle}>1</h3>
                </div>
                <div>
                    <h3 style={contentStyle}>2</h3>
                </div>
                <div>
                    <h3 style={contentStyle}>3</h3>
                </div>
                <div>
                    <h3 style={contentStyle}>4</h3>
                </div>
            </Carousel>
        </div>
    )
}

export default HomeScreen;
