import React from 'react';
import {Button, Col, Layout, Menu, Row} from 'antd';
import {withRouter} from 'react-router-dom';
import useHistory from "../hooks/useHistory";
import {HomeOutlined, SearchOutlined} from '@ant-design/icons'
import {getAppURL} from "../utils/functions";
import {APP_NAMES} from "../utils/constants";

const {Header, Content} = Layout

const PublicLayout = withRouter(props => {
    const {children} = props;
    return (
        <Layout style={{minHeight: '100vh'}}>
            <PublicHeader/>
            <Content>
                {children}
            </Content>
            {/*<Footer style={{textAlign: 'center'}}>Ant Design ©2018 Created by Ant UED</Footer>*/}
        </Layout>
    )
});

const PublicHeader = (props) => {
    const history = useHistory();

    const pushToCompanySignIn = () => {
        window.location.href = `${getAppURL(APP_NAMES.COMPANY)}/sign`
    }

    const pushToSignIn = () => {
        window.location.href = `${getAppURL(APP_NAMES.CUSTOMER)}/sign`
    }

    const pushToSearch = () => {
        history.push("/search");
    }

    const pushToHome = () => {
        history.push("/");
    }

    return (
        <Header style={{display: 'inline-block', width: '100%', zIndex: 2}}>
            <div style={{width: '100%'}}>
                <Row>
                    <Col span={12} style={{height: 58}}>
                        <Menu theme="dark" mode="horizontal">
                            <Menu.Item key="home" icon={<HomeOutlined/>} onClick={pushToHome}/>
                            <Menu.Item key="search" icon={<SearchOutlined/>} onClick={pushToSearch}>Ara</Menu.Item>
                        </Menu>
                    </Col>
                    <Col span={12} style={{height: 58}}>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            height: '100%',
                        }}>
                            <Button type="link" onClick={pushToCompanySignIn}>
                                Firma Girişi
                            </Button>
                            <Button type="link" onClick={pushToSignIn}>
                                Giriş Yap
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Header>
    );
};

export default PublicLayout;
